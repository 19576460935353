"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.subcontractors = void 0;
exports.subcontractors = {
    approve: {
        heading: "Approve Subcontractors",
        noSubcontractors: "There are no subcontractors to be approved",
        description: "Below are subcontractors that have created an account and are pending approval.",
        table: {
            name: "Company Name",
            email: "Contact Email",
            code: "Company Code",
        },
        toasts: {
            success: "Subcontractor approved",
            deleted: "Subcontractor deleted",
            error: "Something went wrong. Please try again.",
        },
        modal: {
            date: "Date Created",
            code: "Company Code",
            email: "Contact Email",
            phone: "Contact Phone Number",
            address: "Address",
            settings: "Settings",
            documents: "Documents",
            confirmDeleteSubcontractor: "Are you sure you want to delete this subcontractor?",
        },
        buttons: {
            view: "View",
            approve: "Approve",
            cancel: "Cancel",
            delete: "Delete",
            reload: "Reload",
        },
    },
    approveTips: {
        heading: "Approve Tips",
        description: "Below are tips that are pending approval.",
        noTips: "There are no tips to be approved",
        table: {
            name: "Tip Name",
            postcode: "Postcode",
            address: "Address",
        },
        toasts: {
            success: "Tip approved",
            deleted: "Tip deleted",
            error: "Something went wrong. Please try again.",
        },
        modal: {
            wasteManagementLicense: "Waste Management License",
            group: "Group",
            address: "Address",
            city: "City",
            postcode: "Postcode",
            confirmDeleteTip: "Are you sure you want to delete this tip?",
        },
        buttons: {
            view: "View",
            approve: "Approve",
            cancel: "Cancel",
            delete: "Delete",
            reload: "Reload",
        },
    },
    tips: {
        headings: {
            search: "Search Tips",
            add: "Add Tip",
            edit: "Edit Tip",
            confirmDelete: "Are you sure you want to delete this tip?",
        },
        form: {
            labels: {
                sitecode: "Site Code",
                name: "Name",
                active: "Active",
                tip: "Tip",
                address: "Address",
                city: "City",
                postcode: "Postcode",
                latitude: "Latitude",
                longitude: "Longitude",
                wasteManagementLicense: "Waste Management License",
                permitNumber: "Permit Number",
                landfillDiversionRate: "Landfill Diversion Rate",
                group: "Group",
                companyCode: "Company Code",
                rate: "Rate",
                notes: "Notes",
                status: "Status",
                siteCode: "Site Code",
            },
            placeholders: {
                sitecode: "Enter site code",
                name: "Enter name",
                address: "Enter address",
                city: "Enter city",
                postcode: "Enter postcode",
                wasteManagementLicense: "Enter license",
                permitNumber: "Enter permit number",
                landfillDiversionRate: "Enter rate",
                group: "Select a group",
                companyCode: "Enter company code",
                rate: "Enter rate",
                notes: "Enter notes",
                status: "Select a status",
                siteCode: "Enter site code",
                notApplicable: "N/A",
            },
        },
        success: {
            patch: "Tip has been updated",
            post: "Tip has been created",
            delete: "Tip has been deleted",
        },
        errors: {
            noTips: "No tips found",
            NA: "N/A",
            error: "Something went wrong. Please try again.",
            noData: "No data available",
            fieldMissing: "Some of the required fields are missing",
        },
        buttons: {
            search: "Search",
            reset: "Reset",
            update: "Update",
            delete: "Delete",
            remove: "Remove",
            cancel: "Cancel",
            edit: "Edit",
            submit: "Submit",
            add: "Add",
        },
        table: {
            active: "Active",
            name: "Name",
            siteCode: "Site Code",
            postcode: "Postcode",
            rate: "Rate",
            address: "Address",
            wasteManagementLicense: "Waste Management License",
            group: "Group",
            city: "City",
            actions: "Actions",
        },
        statuses: {
            active: "Active",
            pending: "Pending",
        },
    },
    jobs: {
        heading: "Subcontractor Jobs",
        noJobsFound: "No jobs found",
        areYouSure: "Are you sure you want to unassign this job?",
        buttons: {
            search: "Search",
            reset: "Reset",
            confirm: "Confirm",
            cancel: "Cancel",
            unassignJob: "Unassign Job",
            showDetails: "Show Details",
        },
        form: {
            date: "Date",
            datePlaceholder: "Select a date or a range",
            jobStatus: "Job Status",
            jobStatusPlaceholder: "Select a job status",
            poolStatus: "Pool Status",
            poolStatusPlaceholder: "Select a pool status",
            jobRef: "Job Reference",
            postcode: "Postcode",
            companyCode: "Subcontractors",
            companyCodePlaceholder: "Select a subcontractor",
        },
        table: {
            jobStatus: "Job Status",
            poolStatus: "Pool Status",
            jobRef: "Job Reference",
            jobId: "Job Id",
            jobDetails: "Job Details",
            assignedSubcontractor: "Assigned Subcontractor",
            collectionDate: "Collection Date",
            postcode: "Postcode",
            invoiceAmount: "Invoice Amount",
            actions: "Actions",
            NA: "N/A",
            description: "Description",
            rejectionReason: "Rejection reason",
        },
        toasts: {
            error: "Something went wrong. Please try again.",
            success: "Job unassigned",
        },
    },
    allocateJobs: {
        buttons: {
            search: "Search",
            submit: "Submit",
            reset: "Reset",
            cancel: "Cancel",
            edit: "Edit",
        },
        headings: {
            bulkAllocation: "Bulk Allocation",
        },
        titles: {
            jobReference: "Job Reference:",
            collectionDate: "Collection Date:",
            postcode: "Postcode:",
            orderType: "Order Type:",
            description: "Description:",
        },
        errors: {
            dontCoverPostcode: "The following subcontractors do not cover the job for the given postcode:",
            jobIsNotFound: "Job with this reference is not found",
            fieldRequired: "This field is required",
            groupInvoice: "Provide a group invoice or ensure that every job has its own invoice amount.",
            catalogueMissingProducts: "The following products are not included in the subcontractor’s catalogue: ",
            noCatalogue: "No catalogue found for this subcontractor",
        },
        labels: {
            subcontractors: "Subcontractors",
            globalInvoiceAmount: "Global Invoice Amount",
            groupName: "Group Name",
            invoiceAmount: "Invoice Amount",
            jobReference: "Job Reference",
            specifyTheReason: "Specify the reason",
        },
        tooltips: {
            invoiceAmount: "The global invoice amount will be applied to jobs that don’t have their own specified invoice amount.",
            groupName: "By providing a group name, the jobs below become a ‘group’. A group’s jobs can only be accepted all at once.",
        },
    },
};
